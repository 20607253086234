<template>
	<div>
		<div class="page-top-box">
		    <div class="page-title-box">
		        <div class="go-back">
		            <el-button type="text" icon="el-icon-back"  @click="$router.push(`/manage/dashboard/hostsales/list/${date}`)">返回</el-button>|
		        </div>
		       当前活动： {{ chartData.activityName }}
		    </div>
		</div>
		<div class="page-content-box">			
			<div id="chartHost"></div>			
			<div class="page-list-box">
			    <el-table
			        :data="dataList"
			        header-row-class-name="page-list-table"
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"
			        stripe
			    >
					<el-table-column prop="date" label="日期"></el-table-column>
					<el-table-column prop="formatAmount" label="销售额"></el-table-column>
					<el-table-column prop="totalNum" label="售票数"></el-table-column>
					<el-table-column prop="orderNum" label="订单数"></el-table-column>					
				</el-table>
			</div>
			<div v-if="data" class="page-pagination-box">
			    <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="getHostList"
			        :current-page.sync="params.pageNo"
			        :page-sizes="[15, 30, 50]"
			        :page-size.sync="params.pageSize"
			        layout="sizes, prev, pager, next"
			        :total="data.totalCount || 0">
			    </el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import { Chart } from '@antv/g2';
export default {
   props: {
       activityId: {
           type: [Number, String],
           default: null,
       },
	   date: {
	       type: [Number, String],
	       default: null,
	   },
   }, 
    data() {
        return {
            dataLoading: false,
			listLoading: false,            
			params:{
			    pageNo:parseInt(this.$route.query.pageNo) || 1,
			    pageSize: parseInt(this.$route.query.pageSize) || 15,			    
			},
			chartData:{},
			data:null,
			dataList: null,
			chart:null,
        };
    },   
	mounted(){
	  this.gethostChart();
	  this.getHostList()
	},	
    methods:{       
		//指定演出销售曲线图
		gethostChart(){
			 this.dataLoading = true;
			this.$request({
			    url: "/report/activitySalesCurve",
			    method: "POST",
				data: {activityId: this.activityId}
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
			            this.chartData = result;
						this.$nextTick(() => {
						    this.drawChart();						    
						})
			        } else {
			            this.$message.error(msg || "请求失败");
			        }
			    })
			    .catch(() => {
			        this.$message.error("请求错误");
			    })
			    .finally(() => {
			        this.dataLoading = false;
			    });
		},
		drawChart(){
			if( this.chart ) this.chart.destroy();
			this.chart = new Chart({
			    container: 'chartHost',
			    autoFit: true,
			    height: 420,
			});		
			
			this.chart.data(this.chartData.lineData);
			var dataChart = []
			this.chartData.lineData.forEach((tab, index) => {
				dataChart.push({
					value: parseInt(tab.销售额),
					value1: tab.订单数,
					
				});
			});
			
			
			var totalAmount = dataChart[0].value;
			var totalOrderNum = dataChart[0].value1;
			
			
			for (var i = 1; i < dataChart.length; i++) {
			  if (dataChart[i].value > totalAmount) {
			    totalAmount = dataChart[i].value;
			  }
			  if (dataChart[i].value1 > totalOrderNum) {
			    totalOrderNum = dataChart[i].value1;
			  }
			}
			
			console.log(totalAmount);
			console.log(totalOrderNum);
			
			this.chart.scale({
			  售票数: {
			    min: 0,
			    max: totalOrderNum,
			  },
			  销售额: {
			    min: 0,
			    max: totalAmount,
			  }
			});
			this.chart.legend({
			  custom: true,
			  items: [
			    { value: '销售额', name: '销售额', marker: { symbol: 'square', style: { fill: '#3182bd', r: 5 } } },
			    { value: '售票数', name: '售票数', marker: { symbol: 'hyphen', style: { stroke: '#fdae6b', r: 5, lineWidth: 3 } } }
			  ],
			});
			this.chart.axis('售票数', {
			  grid: null,
			  title: {
			    style: {
			      fill: '#fdae6b'
			    }
			  },
			  label: {
			    style: {
			      fill: '#fdae6b'
			    }
			  }
			});
			this.chart.axis('销售额', {
			  title: {}
			});
			this.chart.tooltip({
			  shared: true,
			});
			this.chart.interval()
			  .position('name*销售额')
			  .color('#3182bd');
			this.chart.line()
			  .position('name*售票数')
			  .color('#fdae6b')
			  .size(3)
			  .shape('smooth');
			this.chart.point()
			  .position('name*售票数')
			  .color('#fdae6b')
			  .size(3)
			  .shape('circle');
			
			this.chart.interaction('active-region');
			this.chart.removeInteraction('legend-filter'); // 自定义图例，移除默认的分类图例筛选交互
			this.chart.render();
		},
		drawChart222(){
		    if( this.chart ) this.chart.destroy();
		    
		    let _data = [];
		    const _dataArry = [...this.chartData.lineData];
		    		   
		    _dataArry.map( item => {
		        item.value.map( v => {
		            _data.push({
		                name: item.name,
		                year: v.name,
		                value: parseFloat(v.value)
		            })
		        })
		    }) 
		    
		    this.chart = new Chart({
		        container: 'chartHost',
		        autoFit: true,
		        height: 420,
		    });
		    
		    
		    this.chart.data(_data);		
		    
		    if( this.chartData.lineData[0].value.length > 7 ){
		            this.chart.option('slider', {
		                start: (this.chartData.lineData[0].value.length - 7) / this.chartData.lineData[0].value.length,
		                end: 1,
		            });
		    }		    
		    this.chart.tooltip({
		    	showCrosshairs: true,
		    	shared: true,
		    });
		    this.chart.legend('name', {
		    	position: 'top',
		    });
		    this.chart
		    	.line()
		    	.position('year*value')
		    	.color('name')
		    	.shape('smooth')
		    	.tooltip('name*value', (name, value) => {
		    	   // value = this.activeName == 1 ? parseFloat(value).toFixed(2) : value;
		    		return {
		    			name: name,
		    			value: value,
		    		};
		    	});
		    
		    this.chart.render();
		    		   
		    
		},
		//指定演出销售线图下面的列表
		getHostList(){
			this.$request({
			    url: "/report/activitySalesCurvePage",
			    method: "POST",				
				data: {...this.params,activityId: this.activityId},
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
					   this.data = result
			           this.dataList = result.result
			        } else {
			            this.$message.error(msg || "请求失败");
			        }
			    })
			    .catch(() => {
			        this.$message.error("请求错误");
			    })
			    .finally(() => {
			        
			    });
		},
		//选择pageSize
		handleSizeChange(e) {
		    this.params.pageNo = 1;
		    this.params.pageSize = e;
		    this.getHostList();
		},
		//返回
		goBack() {
		    this.$router.push({
		        path: this.redirect,
		    });
		},
		
    }
};
</script>

<style lang="scss" scoped>

.page-tab-box{
    margin: -20px -20px 20px -20px;
    padding: 0 20px;
    border-bottom: 1px #E4E7ED solid;
    ::v-deep .el-tabs__header{
        border-bottom: 0;
        margin: 0;
    }
}
.data-group{
    > ::v-deep .el-card__header{
        font-size: 16px;
        line-height: 22px;
        a{
            font-size: 14px;
        }
    }
}
#chartHost{
	margin-bottom: 50px;
}
</style>